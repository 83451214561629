import React from 'react';

function Modal({toggleOpen}) {
  return (
    <div className='w-screen h-screen font-serif bg-black bg-opacity-80 fixed top-0 right-0 flex justify-center items-center z-40'>
      <div className='modal-bg p-10 rounded-md shadow-md z-50'>
        <h1 className='font-bold text-center rainbow-text-loop text-lg my-5'>WELCOME TO ROYCOIN!</h1>
        <p className='text-white'>
          WANT TO BECOME A PROUD MEMBER OF THE COMMUNITY??
        </p>
        <div className='flex justify-between mt-5'>
          <button className='outline outline-1 outline-[#101f20] bg-[#f73232] text-[#fff] py-2 px-4 hover:bg-transparent hover:text-black'
          onClick={() => window.location.href='https://google.com'}
          >NO</button>
          <button className='outline outline-1 outline-[#101f20] hover:bg-[#101f20] bg-green-500 hover:text-white py-2 px-4 text-[#fff]'
          onClick={() => {toggleOpen(); console.log("Long live RoyLand")}}
          >YES</button>
        </div>
      </div>
    </div>
  )
}

export default Modal
