import React, { useState } from 'react';
import './App.css';

const CursorTrail = () => {
  const [sparks, setSparks] = useState([]);

  const addSpark = (e) => {
    const newSpark = {
      id: Date.now(),
      x: e.clientX,
      y: e.clientY,
    };
    setSparks((prevSparks) => [...prevSparks, newSpark]);

    // Remove the spark after 2 seconds
    setTimeout(() => {
      setSparks((prevSparks) => prevSparks.filter((spark) => spark.id !== newSpark.id));
    }, 2000);
  };

  return (
    <div className="cursor-trail-container" onMouseMove={addSpark}>
      {sparks.map((spark) => (
        <div
          key={spark.id}
          className="spark"
          style={{ left: spark.x, top: spark.y }}
        ></div>
      ))}
    </div>
  );
};

export default CursorTrail;