import {useState, useEffect, useRef} from "react";
import Modal from './Modal' 
import Marquee from "./Marquee";
import MarqueeImg from "./MarqueeImg";
import CursorTrail from "./CursorTrail";
import './App.css';
import mainvideo from './videos/mainvideo1.mp4';
import ImageSection from "./ImageSection";


  const images1 = [
'1.jpeg',
'2.jpeg',
'3.jpeg',
'4.jpeg',
'5.jpeg',
'6.jpeg',
'7.jpeg',
'8.jpeg',
'9.jpeg',
'10.jpeg',
'11.jpeg',
'12.jpeg',
'13.jpeg',
'14.jpeg',
'15.jpeg',
'16.jpeg'
]

const images2 = [
'17.jpeg',
'18.jpeg',
'19.jpeg',
'20.jpeg',
'21.jpeg',
'22.jpeg',
'23.jpeg',
'24.jpeg',
'25.jpeg',
'26.jpeg',
'27.jpeg',
'28.jpeg',
'29.jpeg',
'30.jpeg',
'31.jpeg',
'32.jpeg',
'33.jpeg',
'34.jpeg',
'35.jpeg',
'36.jpeg'
  ];
const words = ['The ticker is $ROY', '·', 'рятівни', '·', 'BRUH LITERALLY JUST A FAIRY', '·', 'The ticker is $ROY', '·', 'рятівни', '·', 'BRUH LITERALLY JUST A FAIRY', '·','The ticker is $ROY', '·', 'рятівни', '·', 'BRUH LITERALLY JUST A FAIRY', '·','The ticker is $ROY', '·', 'рятівни', '·', 'BRUH LITERALLY JUST A FAIRY', '·','The ticker is $ROY', '·', 'рятівни', '·', 'BRUH LITERALLY JUST A FAIRY', '·']; // Words to be displayed in the carousel
const words_reverse = ['Psychologically unstable, based meme, based dev, based artist, autistic holders on the way...', '·', 'Psychologically unstable, based meme, based dev, based artist, autistic holders on the way...', '·', 'Psychologically unstable, based meme, based dev, based artist, autistic holders on the way...', '·']; // Words to be displayed in the carousel

function App() {
  const videoRef = useRef();
  const [show, setShow] = useState(true);

  useEffect(() => {
    // Magic sparkles effect
    const sparklesContainer = document.createElement('div');
    sparklesContainer.classList.add('sparkles-container');
    document.body.appendChild(sparklesContainer);

    document.addEventListener('mousemove', (e) => {
      const spark = document.createElement('div');
      spark.classList.add('spark');
      spark.style.left = `${e.pageX}px`;
      spark.style.top = `${e.pageY}px`;
      sparklesContainer.appendChild(spark);

      setTimeout(() => {
        spark.remove();
      }, 1500);
    });

    return () => {
      sparklesContainer.remove();
    };
  }, []);

  const playVideo = () => {
    videoRef.current.play();
  }
  const toggleOpen = () => {setShow(!show); playVideo()};

  return ( 
    <div className='App'>
      {show && <Modal toggleOpen={toggleOpen}/>}
        <p className="text-lg p-4 font-serif font-bold pink-rainbow-text">
          LITERALLY JUST A FAIRY
        </p>
        <div className="w-full z-10 sticky top-0 border-x-4 2xl:w-2/5 lg:w-3/5 mx-auto font-serif h-fit my-auto bg-pink-300 border-y-4 menu-bar flex flex-wrap justify-center">
          <a href="https://twitter.com/roymagiccoin" target="_blank" rel="noopener noreferrer" className="menu-button rainbowbg better-text my-1">TWITTER/X</a>
          <a href="https://t.me/roycoinchannel" target="_blank" rel="noopener noreferrer" className="menu-button better-text rainbowbg2 my-1">TELEGRAM</a>
          <a href="https://www.pinksale.finance/solana/launchpad/BoGsES1FXxsgvwAJ9TY2J9T79Y6iq86nuZnz3E7qmE7i" className="menu-button better-text rainbowbg3 my-1">JUST ROY</a>
        </div>
      <header className="bg-black z-0 border-x-4 border-t-4 h-fit 2xl:w-2/5 lg:w-3/5 flex flex-col mx-auto" id="home">
        <p className='text-lg p-4 border-b-4 font-serif font-bold rainbowText'>
          Hello, welcome to the fairy tale adventure. Your fate is unknown, unless you buy the ticker $ROY... 
        </p>
        <video ref={videoRef} loop src={mainvideo} className="z-0 w-full" type="video/mp4" />
        <div className="h-fit w-full text-white bg-pink-400 relative z-0">
          {!show && <Marquee words={words} reverse={true} />}
        </div>
      </header>
      <div className='h-fit border-x-4 font-serif 2xl:w-2/5 lg:w-3/5 flex flex-col md:flex-row mx-auto bg-black pb-4' id="info">
        <h3 className="text-md text-left rainbow-text-loop">What is $ROY??</h3>
        <p className='text-lg text-left px-5 pt-5 text-white flex flex-col'>
       
        <p className='flex flex-row-reverse w-full'>
            Once upon a time in the magical kingdom of Solania, magic and technology were intertwined in a harmonious dance. In this kingdom lived Roy, a fairy with the power to heal and protect the beings of the enchanted forest. In addition to her magical gifts, Roy possessed a special green mushroom that granted knowledge and insight into the future to whoever consumed it, a secret kept deep within the enchanted forest.
            <img alt="roysavefairycoin" className='h-fit max-w-[30%] p-2 my-auto' src={'memes/ownmeme1.gif'} />
            <br />
        </p>
        <p className='flex flex-row'>
            <br />
            One day, as Roy pondered the fate of Solania, a magical bull named Bullkhan, known for his ancient wisdom and connection to the mysteries of the universe, consumed Roy's magic mushroom. This act created a deep bond between Roy and Bullkhan, sharing a unique understanding of the ways of time and space.
            <br />
            <img alt="meme" className='h-fit max-w-[25%] p-2 my-auto' src={'memes/ownmeme2.gif'} />
        </p>
        <p>
        <br />
            Bullkhan, with his deep and calm voice, predicted a bright future for Roy in the world of memecoins, a new form of cryptocurrencies that combined fun, creativity and blockchain technology. According to Bullkhan's vision, Roy would become a beacon of hope and wisdom in this new world, guiding other magical beings into an era of prosperity and fun.
            <br />
            <br />
        </p>
        <p className='flex flex-row-reverse'>
            <br />
            While exploring this new path, Roy and Bullkhan encountered two exceptional figures in the deep forest of Solania: Vitalik Butering, the wise wizard of etheria, and Anatoly Yakovenko, the master engineer of Solania, who claimed to be a magical flying dragon.
            <img alt="roysavefairy" className='h-fit max-w-[27%] p-2 my-auto' src={'memes/ownmeme3.gif'} />
        <br />
        </p>
        <br />
        <br />
        The friendship between Roy, Vitalik and Anatoly was growing stronger by the day. Together, they had created a thriving and secure ecosystem where magic and blockchain technology merged in a unique way. As the kingdom celebrated their success, an extraordinary event shook the deep forest on a starry night.
         <br />
        </p>
      </div>
      <div className="h-fit border-x-4 w-full 2xl:w-2/5 lg:w-3/5 flex flex-col mx-auto z-0 relative text-white bg-pink-500">
        {!show && <Marquee words={words_reverse} reverse={false} />}
      </div>
      <div className='min-h-fit border-x-4 font-serif 2xl:w-2/5 lg:w-3/5 flex flex-col bg-black mx-auto pb-4' id="howto">
        <div className="w-full h-fit flex flex-col md:flex-col moneyrain4">
            <div className='text-lg w-full text-left px-5 pt-5 text-white flex flex-row'>
            <h3 className="text-2xl text-right w-fit px-5 rainbow-text-loop">How to buy:</h3>
                <p>
                    1- Create a Solana wallet and deposit some SOL.
                    <br/><br/>
                    2- Go to Roy's <a className="text-pink-300" href="https://www.pinksale.finance/solana/launchpad/BoGsES1FXxsgvwAJ9TY2J9T79Y6iq86nuZnz3E7qmE7i" target="_blank" rel="noopener noreferrer">official pinksale</a> page and buy the token. 
                </p>
            </div>
            {!show && <MarqueeImg images={images1} reverse={true} />} 
        </div>
       <div className="h-fit my-5 relative z-0 text-white bg-pink-600" id="readthis">
          {!show && <Marquee words={words} reverse={true} />}
        </div>
        <div className="roadmap1 w-full h-fit -my-5 py-5">
        <div className="w-full h-fit flex flex-col md:flex-row">
          <h3 className="text-2xl text-left rainbow-text-loop">Roadmap</h3>
          <p className='text-lg moneyrain md:mx-2 text-left rounded-lg px-10 md:py-5 m-2 better-text md:px-10 pt-5 text-white'>
            <b className='text-xl'>STAGE 1</b>
            <br/>
            <li>Launching RoySaveFairy Website and Community</li>
            <li>Presale on Pinksale</li>
            <li>Launch on Raydium</li>
            <li>Buyback the floor</li>
            <li>Launch marketing</li>
          </p>
          <p className='text-lg text-left px-10 rounded-lg moneyrain2 md:py-5 better-text md:px-10 m-2 md:mx-2 pt-5 text-white'>
            <b className='text-xl'>STAGE 2</b>
            <br/>
            <li>Listing CMC & CGK</li>
            <li>Top KOL'S Telegram & Twitter</li>
            <li>#RoySaveFairy Trending Global</li>
            <li>Listing on CEX's</li>
          </p>
          <p className='text-lg text-left px-10 md:py-5 md:py-10 rounded-lg better-text moneyrain5 m-2 md:mx-2 pt-5 text-white'>
            <b className='text-xl'>STAGE 3</b>
            <br/>
            <li>Community take over</li>
            <li>CTO Twitter Spaces</li>
            <li>Enter the top memecoin leaderboard</li>
            <li>Paid ADS on dexscreener trends</li>
            <li>New listings CEXs</li>
          </p>
        </div>
        <p className='text-lg rounded-lg border-4 bg-[#00000060] mx-12 pb-6 border-pink-600 text-left px-5 text-white'> 
          <br /> 
        $Roy is here to save you from the misery. Choose rich, every day,buy the ticker and save you, fck the system,TRUST $ROY.
          <br />
          <br /> 
          Buy the ticker if you have profound mental and emotional issues, Roy loves you. 
        </p>
        {!show && <MarqueeImg images={images2} reverse={false} />} 
        </div>
      </div>
      <div className="h-fit border-x-4 w-full z-0 relative 2xl:w-2/5 lg:w-3/5 flex flex-col mx-auto text-white bg-pink-700">
        {!show && <Marquee words={words_reverse} reverse={false} />}
      </div>
        <div className='min-h-fit border-x-4 font-serif 2xl:w-2/5 lg:w-3/5 flex flex-col mx-auto relative bg-[#ffffff80] moneyrain3 z-0 pb-20' >
            <h3 className="text-2xl text-left rainbow-text-loop">Partner with:</h3>
            <ImageSection />
        </div>
 {/*     <div className='min-h-fit border-x-4 font-serif 2xl:w-2/5 lg:w-3/5 flex flex-col mx-auto relative z-0 bg-black pb-20' id="memes">
    <h3 className="text-2xl text-left rainbow-text-loop">MEEEEMEEEEEEEESSSS</h3>
    <p className='text-md text-left px-5 pb-4 text-white'>
          Click to copy and explode the internet with fairy magic!!!
        </p>
    <    <ImageSection />
          </div> */}

    <div className="w-full h-fit bg-pink-200 text-black fixed bottom-0">
        <p className="p-2 font-serif text-xs">
            *This is dedicated to  Milady Cabal Retardios
        </p>
      </div>
      <CursorTrail />
    </div>
  );
/*return (
    <div className="bg-gray-100 text-gray-800 font-serif">
      <header className="bg-pink-300 py-4">
        <div className="container mx-auto">
          <h1 className="text-white text-4xl font-bold">Roy Fairy Memecoin</h1>
        </div>
      </header>
      <nav className="py-2">
        <div className="container mx-auto">
          <ul className="flex justify-center">
            <li className="mx-4"><a href="#" className="text-gray-800">Home</a></li>
            <li className="mx-4"><a href="#" className="text-gray-800">About</a></li>
            <li className="mx-4"><a href="#" className="text-gray-800">Roadmap</a></li>
            <li className="mx-4"><a href="#" className="text-gray-800">Tokenomics</a></li>
            <li className="mx-4"><a href="#" className="text-gray-800">Contact</a></li>
          </ul>
        </div>
      </nav>
      <main>
        <section className="bg-white py-12 text-center">
          <div className="container mx-auto">
            <h2 className="text-3xl font-bold mb-6">Join the Roy Fairy Revolution!</h2>
            <p className="text-lg mb-8">Invest in the most magical memecoin of the 2000s!</p>
            <a href="#" className="btn bg-pink-300 text-white px-8 py-3 font-bold rounded-full hover:bg-pink-500">Get Started</a>
          </div>
        </section>
      </main>
      <footer className="bg-pink-300 py-4 text-center">
        <div className="container mx-auto">
          <p>&copy; 2024 Roy Fairy Memecoin. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );*/
}

export default App;
