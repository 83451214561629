import React, { useState, useEffect, useRef } from 'react';

const Marquee = ({ words, reverse }) => {
  const [index, setIndex] = useState(0);
  const marqueeRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex(prevIndex =>
        reverse
          ? (prevIndex - 1 + words.length * 5) % (words.length * 5)
          : (prevIndex + 1) % (words.length * 5)
      );
    }, 2000); // Change the interval as needed
    return () => clearInterval(interval);
  }, [words.length, reverse]);

  useEffect(() => {
    const marqueeWidth = marqueeRef.current.offsetWidth;
    const wordsCount = Math.ceil(marqueeWidth / 100); // Adjust the divisor based on the average width of your words
    setIndex(prevIndex => (prevIndex + wordsCount) % (words.length * 2));
  }, [marqueeRef, words.length]);

  return (
    <div className="z-0 marquee-container" ref={marqueeRef}>
      <div className={!reverse ? "z-0 marquee" : "z-0 marquee-reverse"}>
        {words.map((word, idx) => (
          <span key={idx} id={index} className="z-0 marquee-item">
            {word}
          </span>
        ))}
        {words.map((word, idx) => (
          <span key={idx + words.length} className="z-0 marquee-item">
            {word}
          </span>
        ))}
      </div>
    </div>
  );
};

export default Marquee;
