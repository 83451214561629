import React from 'react';
//import { CopyToClipboard } from 'react-copy-to-clipboard';

const ImageSection = () => {
  const images = [
    'avedex.png',
    'coingecko.png',
    'coinmarketcap.png',
    'dexscrr.png',
    'dextools.png',
    'dexview.png',
    'pinksale.png',
    'raydium.png',
    'solscan.png'
  ];
  // const [copied, setCopied] = useState(Array(images.length).fill(false));

  /*const handleCopy = (index) => {
    const newCopied = [...copied];
    newCopied[index] = true;
    setCopied(newCopied);
    setTimeout(() => {
      newCopied[index] = false;
      setCopied([...newCopied]);
    }, 1500); // Reset copied state after 1.5 seconds
  };
*/
  return (
    <div className="grid grid-cols-2 pt-0 p-10 md:grid-cols-3 gap-4">
      {images.map((image, index) => (
        <div key={index} className={`relative p-6 bg-[#ffffff90] rounded-lg flex justify-center items-center`}>
          <img src={`partners/${image}`} alt="" className="w-full h-auto" />
{/*          <CopyToClipboard text={`${window.location.origin}/memes/${image}`} onCopy={() => handleCopy(index)}>
            <button className="absolute top-2 right-2 bg-pink-500 text-white px-2 py-1 rounded-md focus:outline-none">
              {copied[index] ? 'Copied!' : 'Copy Link'}
            </button>
          </CopyToClipboard> */}
        </div>
      ))}
    </div>
  );
};

export default ImageSection;
