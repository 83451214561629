import React from 'react';

const MarqueeImg = ({ images, reverse }) => {


  // const colors = [
  //   '#fff',
  //   '#fff',
  //   '#fff',
  //   '#000',
  //   '#fff',
  //   '#fff',
  //   '#000',
  //   '#fff',
  //   '#fff',
  //   '#fff',
  //   '#fff',
  //   '#fff',
  //   '#fff',
  //   '#000',
  //   '#fff',
  //   '#fff',
  //   '#000',
  //   '#fff',
  //   '#fff',
  //   '#fff',
  //   '#fff',
  //   '#fff',
  //   '#fff',
  //   '#fff',
  //   '#fff',
  //   '#fff',
  //   '#fff'
  // ];
  return (
    <section className={reverse ? "enable-animation_reverse_v2" : "enable-animation_v2"}>
        <div className="marquee_v2 m-6">
          <ul className="marquee__content_v2">
            {images.map((image, idx) => (
                <div className={`marquee__item_v2`}>
                    <img src={`memes/${image}`} alt="" />
                </div>
            ))}
          </ul>

          <ul aria-hidden="true" className="marquee__content_v2">
            {images.map((image, idx) => (
                <div className={`marquee__item_v2`}>
                    <img src={`memes/${image}`} alt="" />
                </div>
            ))}
          </ul>
        </div>
      </section>
  );
};

export default MarqueeImg;
